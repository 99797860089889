import styles from "./Tag.module.scss";

const Tag = ({
  children,
  size = "md",
  link = false,
  style = {},
  color = "dark",
  textTransform = false,
}) => {
  return (
    <span
      className={`${styles.tag} ${styles[size + "Size"]} ${
        styles[color + "Color"]
      } ${link ? styles.linkTag : ""} ${
        !textTransform ? styles.noTextTransform : ""
      }`}
      style={style ?? ""}
    >
      {children}
    </span>
  );
};

export default Tag;
